<template>
  <div v-if="project.billing">
    <a
      class="max-char-width w28"
      :href="'mailto:' + project.billing.contact_email"
    >
      {{project.billing.contact_name || '-'}}
      <span
        v-if="project.billing.company_name"
      > / {{project.billing.company_name}}</span>
    </a>
  </div>
  <div v-else>-</div>
</template>
<script>
export default {
  props: {
    project: Object,
  },
};
</script>
