<template>
  <button
    class="max-char-width w28"
    @click="$emit('on-project-click', project)"
  >{{project.name}}</button>
</template>
<script>
export default {
  props: {
    project: Object,
  },
};
</script>
