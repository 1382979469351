<template>
  <QuickPreviewView
    v-if="previewProject && previewProject.key"
  >
    <template v-slot:left>
      <ul class="item-box">
        <li
          v-for="project in filteredProjects"
          :key="project.key"
          @click="showPreviewProject(project)"
          :class="{active: project.key === previewProject.key}"
        >
        {{project.name}}
        </li>
      </ul>
    </template>
    <template v-slot:title>
      <h1>{{previewProject.name}}</h1>
      <div class="tools">
        <ProjectItemLicense :project="previewProject" largeStyle/>
        <router-link
          class="btn"
          :to="{name: 'ProjectPage', params: {projectKey: previewProject.key}}"
        >Open</router-link>
        <button
          @click="hidePreviewProject"
          class="btn"
        >X</button>
      </div>
    </template>
    <template v-slot:content>
      <ProjectPreview
        :projectKey="previewProject.key"
        @update="onPreviewUpdate"
      />
    </template>
  </QuickPreviewView>
</template>
<script>
import {
  ref,
  onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
  ProjectList,
  FilterableItem,
  Filterables,
  ProjectGroup,
  DefaultProjectGroupsOrder,
} from '@/store/modules/admin';
import {
  ProjectStatus,
} from '@/store/modules/project';
import { RequestState, RequestStateStatus } from '@/api/core';
import QuickPreviewView from '@/components/admin/QuickPreviewView.vue';
import ProjectPreview from '@/components/admin/ProjectPreview.vue';
import ProjectItemLicense from '@/components/admin/ProjectItemLicense.vue';

export default {
  components: {
    QuickPreviewView,
    ProjectPreview,
    ProjectItemLicense,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const previewProject = ref({});
    const projectKey = ref(route.params.key);

    const store = useStore();
    const pageLimit = 150;
    const state = ref(new RequestState());
    const projects = ref({});
    const filteredProjects = ref([]);
    const itemGroup = ref(route.params.type || 'active');
    const filterables = ref(new Filterables({ items: [] }));
    const defaultProjectGroups = [...DefaultProjectGroupsOrder];
    const ignoreProjectGroups = ref([
      ProjectGroup.RoanuzDevelopers.value,
      ProjectGroup.RoanuzInternals.value,
      ProjectGroup.Test.value,
    ]);
    const ignoredStatus = ref([]);

    const showPreviewProject = (project) => {
      previewProject.value = project;
      if (route && route.params && route.params.type) {
        router.push({
          name: 'adminQuickProjectPreview',
          params: {
            type: route.params.type,
            key: project.key,
          },
          query: {
            page: route.query.page,
          },
        });
      } else if (route && route.query && route.query.search) {
        router.push({
          name: 'adminProjectPreview',
          params: {
            key: project.key,
          },
          query: {
            search: route.query.search,
          },
        });
      } else {
        router.push({
          name: 'adminQuickProjectPreview',
          params: {
            type: 'active',
            key: project.key,
          },
          query: {
            page: route.query.page,
          },
        });
      }
    };

    const hidePreviewProject = () => {
      previewProject.value = {};
      router.push({
        name: 'adminProjects',
      });
      store.commit('admin/updatePreviewProject', { project: null });
      return false;
    };

    const updateFilter = () => {
      const allItems = [...projects.value.projects];
      const projectGroups = {};
      const projectStatus = {};
      defaultProjectGroups.forEach((group) => {
        projectGroups[group.value] = {
          group,
          count: 0,
        };
      });

      const items = [
        new FilterableItem({
          name: 'Active',
          key: 'active',
          route: { query: { group: 'active' } },
        }),
        new FilterableItem({
          name: 'Trial',
          key: 'trial',
          route: { query: { group: 'trial' } },
        }),
        new FilterableItem({
          name: 'Verification',
          key: 'verification',
          route: { query: { group: 'verification' } },
        }),
        new FilterableItem({
          name: 'Incomplete',
          key: 'incomplete',
          route: { query: { group: 'incomplete' } },
        }),
        new FilterableItem({
          name: 'All',
          key: 'all',
          route: { query: { group: 'all' } },
        }),
        new FilterableItem({
          name: 'Pending',
          key: 'pending',
          route: { query: { group: 'pending' } },
        }),
      ];

      allItems.forEach((project) => {
        if (project.group && !projectGroups[project.group]) {
          const name = project.group.replace('_', ' ');
          projectGroups[project.group] = {
            group: { name },
            count: 0,
          };
        }

        if (!project.status) {
          project.status = ProjectStatus.Unknown;
        }

        if (!projectStatus[project.status.value]) {
          const name = project.status.value.replace('_', ' ');
          projectStatus[project.status.value] = {
            group: { name },
            count: 0,
          };
        }

        projectStatus[project.status.value].count += 1;
        projectGroups[project.group].count += 1;
      });

      if (projectGroups) {
        items.push(FilterableItem.SeperatorItem());
        Object.keys(projectGroups).forEach((key) => {
          const value = projectGroups[key];
          const name = value.group.key;
          items.push(
            new FilterableItem({
              name: `Hide ${name}`,
              key: `group||${key}`,
              isCheckbox: true,
              number: value.count,
              selected: ignoreProjectGroups.value.indexOf(key) > -1,
            }),
          );
        });
      }

      if (projectStatus && Object.keys(projectStatus).length > 1) {
        items.push(FilterableItem.SeperatorItem());
        Object.keys(projectStatus).forEach((key) => {
          const value = projectStatus[key];
          const { name } = value.group;
          items.push(
            new FilterableItem({
              name: `Hide ${name}`,
              key: `status||${key}`,
              isCheckbox: true,
              number: value.count,
              selected: ignoredStatus.value.indexOf(key) > -1,
            }),
          );
        });
      }

      filteredProjects.value = [...allItems];
      filteredProjects.value = filteredProjects.value.filter(
        (project) => ignoreProjectGroups.value.indexOf(project.group) < 0,
      );

      filteredProjects.value = filteredProjects.value.filter(
        (project) => ignoredStatus.value.indexOf(project.status.value) < 0,
      );

      filterables.value.activeItem = itemGroup.value;
      filterables.value.items = items;
      // showPreviewProjectUsingKey('RS_P_1382733182755016725');
    };

    const fetchData = () => {
      state.value.status = RequestStateStatus.Loading;
      let params = {};
      const group = itemGroup.value;

      if (group === 'active') {
        params = {
          show_active: 1,
          show_billing_verified: 1,
          show_active_free_trail_projects: 0,
        };
      } else if (group === 'trial') {
        params = {
          show_active: 1,
          show_active_free_trail_projects: 1,
        };
      } else if (group === 'verification') {
        params = {
          show_active: 1,
          show_billing_verification_required: 1,
        };
      } else if (group === 'incomplete') {
        params = {
          show_active: 1,
          show_billing_not_available: 1,
        };
      } else if (group === 'pending') {
        params = {
          show_active: 1,
          show_pending_status_payment_pending: 1,
        };
      }

      params = {
        limit: pageLimit,
        page: route.query.page,
        ignored_project_groups: ignoreProjectGroups.value.join(','),
        ignored_status: ignoredStatus.value.join(','),
        ...params,
      };

      if (route.query.search) {
        return ProjectList.searchQuery(route.query.search, 'project').then((list) => {
          filteredProjects.value = list.data.data.docs;
        });
      }
      return ProjectList.filterQuery(params).then((projectList) => {
        projects.value = projectList;
        filteredProjects.value = [...projectList.projects];
        state.value.status = RequestStateStatus.Loaded;
        updateFilter();
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    const onPreviewUpdate = (project) => {
      previewProject.value = project;
    };

    const fetchProject = () => {
      if (projectKey.value) {
        ProjectList.fetchProject(projectKey.value).then((response) => {
          previewProject.value = response;
        });
      }
    };

    onMounted(() => {
      if ((route.query.group || route.query.search || route.query.page) && route.params.key) {
        fetchData();
        fetchProject();
      }
    });

    return {
      previewProject,
      state,
      projects,
      filterables,
      itemGroup,
      filteredProjects,
      onPreviewUpdate,
      showPreviewProject,
      hidePreviewProject,
    };
  },
};
</script>
