<template>
  <div
    v-if="project && project.project_license
    && project.project_license.product"
    class="project-item-license"
    :class="{
      large: largeStyle,
      standard: project.project_license.license_key.isStandard,
      enterprise: project.project_license.license_key.isEnterprise,
      premium: project.project_license.license_key.isPremium,
      package: project.project_license.license_key.isPackage,
    }"
    :alt="project.project_license.name"
  >
    <span
      v-if="project.project_license.license_key.isStandard"
    >{{largeStyle ? project.project_license.common_name : 'S'}}</span>
    <span
      v-else-if="project.project_license.license_key.isEnterprise"
    >{{largeStyle ? project.project_license.common_name : 'E'}}</span>
    <span
      v-else-if="project.project_license.license_key.isPremium"
    >{{largeStyle ? project.project_license.common_name : 'P'}}</span>
    <span
      v-else-if="project.project_license.license_key.isPackage"
    >{{largeStyle ? project.project_license.common_name : 'PA'}}</span>
    <span
      v-else
      class="unknown"
    >{{project.project_license.common_name}}</span>
  </div>
</template>
<script>
export default {
  props: {
    project: Object,
    largeStyle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
