<template>
  <div class="filterables">
    <div class="filter-container">
      <ul class="filter-items">
        <li
          v-for="item in filterables.items"
          :key="item.key"
          :class="{
            active: filterables.activeItem === item.key,
            selectable: !item.isCheckbox,
            selected: item.selected,
            hr: item.key === '-',
          }"
          @click="onSelectedFilteritem(item)"
        >
        <label
          v-if="item.isCheckbox"
        >
          <input
            @change="onFilterItemToggle(item)"
            type="checkbox"
            v-model="item.selected"
          />
          {{item.name}}
          <FilterableNumberView :item="item" />
        </label>
        <router-link
          v-else-if="item.route"
          :to="item.route"
        >
          {{item.name}}
          <FilterableNumberView :item="item" />
        </router-link>
        <template
          v-else-if="item.key !== '-'"
        >
          {{item.name}}
          <FilterableNumberView :item="item" />
        </template>
        </li>
      </ul>
    </div>
    <div class="filtered-content">
      <slot />
    </div>
  </div>
</template>
<script>
import FilterableNumberView from '@/components/admin/FilterableNumber.vue';

export default {
  props: {
    filterables: Object,
  },
  components: {
    FilterableNumberView,
  },
  setup(props, { emit }) {
    const onSelectedFilteritem = (item) => {
      emit('item-select', item);
    };

    const onFilterItemToggle = (item) => {
      emit('item-toggle', item);
    };

    return {
      onSelectedFilteritem,
      onFilterItemToggle,
    };
  },
};
</script>
