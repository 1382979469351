<template>
  <span
    v-if="item.number !== null"
    class="number"
    :class="{low: item.number < 1}"
  >{{item.number}}
  </span>
</template>
<script>
export default {
  props: {
    item: Object,
  },
};
</script>
